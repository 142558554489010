import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppLoader from '@wieldy/components/AppLoader';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import UploadFile from '@wieldy/components/UploadFile';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, Row} from 'antd';
import {useForm, useWatch} from 'antd/es/form/Form';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {applicantApi} from '../../../../../../api/applicantApi';
import {useEditor} from '../../../../../../hooks/useEditor';
import {useGetBlob} from '../../../../../../services/globalService';
import Editor from '../../../../../../shared/components/Editor/Editor';
import PhoneInput from '../../../../../../shared/components/PhoneInput';
import {setSummary} from '../../../../../../store/features/resource/resourceSlice';
import ApplicantHelper from '../../../../../../utils/ApplicantHelper';
import CommonHelper from '../../../../../../utils/CommonHelper';
import {validatePhoneNumber} from '../../../../../../utils/helpers/AppHelper';
import {
  getPhoneNumber,
  getPhoneNumberForm,
} from '../../../../../../utils/helpers/DataFormatHelper';
import {preventNegativeNumber} from '../../../../../../utils/helpers/preventNegativeNumber';
import LocationHelper from '../../../../../../utils/LocationHelper';
import ResourceHelper from '../../../../../../utils/ResourceHelper';
import {validator} from '../SupplementalInfo/validation';

const SummaryForm = ({onContinue}) => {
  const dispatch = useDispatch();
  const {summary, isInit} = useSelector(({resource}) => resource);
  const ref = useRef();

  const getDownloadResourceResume = useGetBlob(summary.temporary_resume_key);

  const handleSubmit = (values) => {
    const currentValues = {
      ...values,
      phone_number: values.phone_number
        ? getPhoneNumberForm(values.phone_number)
        : null,
    };
    if (values.availability === 'not_available') {
      dispatch(
        setSummary({...currentValues, available_to: null, available_on: null}),
      );
    } else {
      dispatch(setSummary(currentValues));
    }

    onContinue();
  };

  const [form] = useForm();
  const countryValue = useWatch('country', form);
  const availabilityValue = useWatch('availability', form);

  const isUSA = countryValue === 'united_states';
  const isEngaged = availabilityValue === 'engaged';

  const {getEditorValue, init} = useEditor({form, key: 'about'});

  const checkExistingEmail = async (rules, value) => {
    if (summary.email === value) {
      return Promise.resolve();
    }

    try {
      await applicantApi.checkExistingApplicantEmail(value);

      return Promise.reject('Applicant already exists under your account');
    } catch {
      return Promise.resolve();
    }
  };

  if (!isInit) {
    return <AppLoader />;
  }

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          autoComplete='off'
          initialValues={{
            ...summary,
            phone_number: getPhoneNumber(summary.phone_number),
          }}>
          <AppFormBody>
            <div style={{width: '280px'}}>
              <Form.Item
                name='first_name'
                label='First Name'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('First Name'),
                  },
                ]}>
                <AppInput placeholder={getInputPlaceHolder('first name')} />
              </Form.Item>
              <Form.Item
                name='last_name'
                label='Last Name'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Last Name'),
                  },
                ]}>
                <AppInput placeholder={getInputPlaceHolder('last name')} />
              </Form.Item>
              <Form.Item
                name='type'
                label='Applicant Type'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Applicant Type'),
                  },
                ]}>
                <AppSelect
                  showSearch={false}
                  placeholder={getSelectPlaceHolder('applicant type')}
                  options={ApplicantHelper.applicationType()}
                />
              </Form.Item>
              <Form.Item
                name='title'
                label='Title'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Title'),
                  },
                ]}>
                <AppInput placeholder={getInputPlaceHolder('title')} />
              </Form.Item>
            </div>
            <Row>
              <Col style={{maxWidth: 280, width: '100%'}} className='mr-2'>
                <Form.Item
                  name='country'
                  label='Country'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Country'),
                    },
                  ]}>
                  <AppSelect
                    showSearch
                    placeholder={getSelectPlaceHolder('country')}
                    options={LocationHelper.getCountries()}
                  />
                </Form.Item>
              </Col>
              {isUSA && (
                <Col style={{maxWidth: 280, width: '100%'}} className='mr-2'>
                  <Form.Item
                    name='us_state'
                    label='State'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('State'),
                      },
                    ]}>
                    <AppSelect
                      showSearch
                      disabled={!isUSA}
                      placeholder={getSelectPlaceHolder('state')}
                      options={LocationHelper.getUsStates()}
                    />
                  </Form.Item>
                </Col>
              )}

              {!!countryValue && (
                <Col style={{maxWidth: 280, width: '100%'}} className='mr-2'>
                  <Form.Item
                    name='city'
                    label='City'
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('City'),
                      },
                    ]}>
                    <AppInput placeholder={getSelectPlaceHolder('city')} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <div style={{width: '280px'}}>
              <Form.Item
                name='years_experience'
                label='Years of Work Experience'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Years'),
                  },
                  {validator},
                ]}>
                <AppInput
                  min={0}
                  type='number'
                  onKeyPress={preventNegativeNumber}
                  placeholder={getSelectPlaceHolder('years')}
                />
              </Form.Item>
              <Form.Item
                name='availability'
                label='Availability Status'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Availability Status'),
                  },
                ]}>
                <AppSelect
                  disabled={isEngaged}
                  placeholder={getSelectPlaceHolder('status')}
                  options={ResourceHelper.availabilityStatus()}
                />
              </Form.Item>
              <Form.Item
                label='Available From'
                name='available_on'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Available From'),
                  },
                ]}>
                <AppDatePicker style={{width: '100%'}} format='MM-DD-YYYY' />
              </Form.Item>
              <Form.Item
                label='Available To'
                name='available_to'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Available To'),
                  },
                ]}>
                <AppDatePicker style={{width: '100%'}} format='MM-DD-YYYY' />
              </Form.Item>
              <Form.Item
                label='Minimum Engagement'
                name='assignment_duration_min'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Minimum Engagement'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('duration')}
                  options={ResourceHelper.assignmentDurations()}
                />
              </Form.Item>
              <Form.Item
                label='Maximum Engagement'
                name='assignment_duration_max'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Maximum Engagement'),
                  },
                ]}>
                <AppSelect
                  // showSearch={true}
                  placeholder={getSelectPlaceHolder('duration')}
                  options={ResourceHelper.assignmentDurations()}
                />
              </Form.Item>
              <Form.Item
                label='Work Status'
                name='work_status'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Work Status'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('status')}
                  options={CommonHelper.jobStatus()}
                />
              </Form.Item>
              <Form.Item
                label='Local Time Zone'
                name='timezone'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Local Time Zone'),
                  },
                ]}>
                <AppSelect
                  showSearch
                  placeholder={getSelectPlaceHolder('time zone')}
                  options={ResourceHelper.timezones()}
                />
              </Form.Item>
              <Form.Item
                label='Available for Overtime'
                name='overtime_availability'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Available for Overtime'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('availability')}
                  options={ResourceHelper.availabilityOvertime()}
                />
              </Form.Item>
              <Form.Item
                label='Available to Travel'
                name='travel_availability'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Available to Travel'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('availability')}
                  options={ResourceHelper.travelAvailability(true)}
                />
              </Form.Item>
              <Form.Item
                label='Willing to Relocate'
                name='willing_to_relocate'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Willing to Relocate'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('relocation option')}
                  options={ResourceHelper.willingToRelocate()}
                />
              </Form.Item>
              <Form.Item
                label='Work Arrangement'
                name='work_arrangement'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Work Arrangement'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('arrangement')}
                  options={ResourceHelper.workArrangement()}
                />
              </Form.Item>
              <Form.Item
                label='Part Time Assignment'
                name='part_time_assignment'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Part Time Assignment'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('availability')}
                  options={ResourceHelper.yesNoOptions()}
                />
              </Form.Item>
              <Form.Item
                label='Education Degree Level'
                name='education_degree_level'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Education Degree level'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('degree level')}
                  options={ResourceHelper.degreeLevel()}
                />
              </Form.Item>
              <Form.Item
                label='Visa Status'
                name='visa_status'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Visa Status'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('status')}
                  options={ResourceHelper.visaStatus()}
                />
              </Form.Item>
              <Form.Item
                name='email'
                label='Email Address'
                validateFirst
                validateDebounce={1000}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Email address'),
                  },
                  {
                    type: 'email',
                    message: 'Enter correct email address',
                  },
                  {
                    validator: checkExistingEmail,
                  },
                ]}>
                <AppInput
                  placeholder={getInputPlaceHolder('email address')}
                  style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                />
              </Form.Item>

              <Form.Item
                name='phone_number'
                label='Phone Number'
                rules={[
                  {
                    validator: validatePhoneNumber,
                  },
                ]}>
                <PhoneInput
                  style={{minWidth: '280px', width: '280px', maxWidth: '100%'}}
                />
              </Form.Item>
            </div>

            <Form.Item
              label='Resume / CV'
              name='resume_key'
              rules={[
                {
                  required: false,
                },
              ]}>
              <UploadFile
                value={summary.resume_key}
                defaultValue={getDownloadResourceResume.data || null}
              />
            </Form.Item>

            <Form.Item
              label='About'
              name='about'
              rules={[
                {
                  required: false,
                  message: getRequiredMessage('About'),
                },
              ]}>
              <Editor
                isUseAntdform
                ref={ref}
                key={init.length ? 'withData' : 'empty'}
                getEditorValue={getEditorValue}
                placeholder={getInputPlaceHolder('text')}
                initValue={init}
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

SummaryForm.propTypes = {
  onContinue: PropTypes.func,
  onSave: PropTypes.func,
};

export default SummaryForm;
