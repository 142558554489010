import Icon, {AreaChartOutlined, IdcardOutlined} from '@ant-design/icons';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import React from 'react';
import {FaRegHandshake} from 'react-icons/fa';
import {
  RiBriefcaseFill,
  RiBuildingLine,
  RiCalendarTodoFill,
  RiCoinLine,
  RiCoinsLine,
  RiFileTransferLine,
  RiFunctionLine,
  RiGroupLine,
  RiQuestionnaireLine,
  RiSettings4Line,
  RiTeamLine,
  RiUser2Line,
} from 'react-icons/ri';

import {supplierTypes} from '../constants/supplier-types';

const client = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    icon: <Icon component={RiFunctionLine} />,
  },
  {
    id: 'suppliers',
    title: 'Suppliers',
    path: '/suppliers/browse',
    icon: <Icon component={RiBuildingLine} />,
    access: USER_CREDENTIALS.USER_TYPES.client,
  },
  {
    id: 'resources',
    title: 'Resources',
    path: '/resources/all',
    icon: <Icon component={RiTeamLine} />,
  },
  {
    id: 'divider',
    isDivider: true,
  },
  {
    id: 'requisitions',
    title: 'Requisitions',
    path: '/requisitions/all',
    icon: <Icon component={RiBriefcaseFill} />,
  },
  {
    id: 'inquiries',
    title: 'Inquiries',
    path: '/inquiries/all',
    icon: <Icon component={RiQuestionnaireLine} />,
  },
  {
    id: 'submissions',
    title: 'Submissions',
    path: '/submissions/all',
    icon: <Icon component={RiFileTransferLine} />,
  },
  {
    id: 'candidates',
    title: 'Candidates',
    path: '/candidates/all',
    icon: <Icon component={RiGroupLine} />,
  },
  {
    id: 'engagements',
    title: 'Engagements',
    path: '/engagements/all',
    icon: <Icon component={FaRegHandshake} />,
  },
  {
    id: 'divider-1',
    isDivider: true,
  },
  {
    id: 'timecards',
    title: 'Timecards',
    path: '/timecards/submitted',
    icon: <Icon component={RiCalendarTodoFill} />,
  },
  {
    id: 'expenses',
    title: 'Expenses',
    path: '/expenses/submitted',
    icon: <Icon component={RiCoinsLine} />,
  },
  {
    id: 'financials',
    title: 'Financials',
    path: '/financials',
    icon: <Icon component={RiCoinLine} />,
  },
  {
    id: 'reports',
    title: 'Analytics',
    path: '/reports',
    icon: <Icon component={AreaChartOutlined} />,
  },
  {
    id: 'divider-2',
    isDivider: true,
  },
  {
    id: 'administration',
    title: 'Administration',
    path: '/administration',
    icon: <Icon component={RiSettings4Line} />,
  },
];

const supplierItems = client.map((item) => {
  if (item.id === 'suppliers') {
    item = {
      ...item,
      id: 'clients',
      path: '/clients/browse',
      title: 'Clients',
      icon: <Icon component={RiBuildingLine} />,
    };
  }
  if (item.id === 'requisitions') {
    item = {...item, path: '/requisitions/browse'};
  }
  if (item.id === 'timecards') {
    item = {...item, path: '/timecards/new'};
  }
  if (item.id === 'expenses') {
    item = {...item, path: '/expenses/new'};
  }
  return item;
});

const supplier = supplierItems.reduce((acc, item, index) => {
  if (item.id === 'clients') {
    supplierItems.splice(index + 1, 0, {
      id: 'applicants',
      title: 'Applicants',
      path: '/applicants',
      icon: <Icon component={IdcardOutlined} />,
      access: {
        supplierType: [
          supplierTypes.staffingAgency,
          supplierTypes.recruitingAgency,
        ],
      },
    });
  }

  return acc;
}, supplierItems);

const resource = [
  {
    id: 'timecards',
    title: 'Timecards',
    path: '/timecards/new',
    icon: <Icon component={RiCalendarTodoFill} />,
  },
  {
    id: 'expenses',
    title: 'Expenses',
    path: '/expenses/new',
    icon: <Icon component={RiCoinsLine} />,
  },
];
const marketplace = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    icon: <Icon component={RiFunctionLine} />,
  },
  {
    id: 'accounts',
    title: 'Accounts',
    path: '/accounts/clients',
    icon: <Icon component={RiUser2Line} />,
  },
];

const recruiter = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    icon: <Icon component={RiFunctionLine} />,
  },
  {
    id: 'suppliers',
    title: 'Suppliers',
    path: '/suppliers/browse',
    icon: <Icon component={RiBuildingLine} />,
    access: USER_CREDENTIALS.USER_TYPES.client,
  },
  {
    id: 'resources',
    title: 'Resources',
    path: '/resources/all',
    icon: <Icon component={RiTeamLine} />,
  },
  {
    id: 'divider',
    isDivider: true,
  },
  {
    id: 'requisitions',
    title: 'Requisitions',
    path: '/requisitions/all',
    icon: <Icon component={RiBriefcaseFill} />,
  },
  {
    id: 'inquiries',
    title: 'Inquiries',
    path: '/inquiries/all',
    icon: <Icon component={RiQuestionnaireLine} />,
  },
  {
    id: 'submissions',
    title: 'Submissions',
    path: '/submissions/all',
    icon: <Icon component={RiFileTransferLine} />,
  },
  {
    id: 'candidates',
    title: 'Candidates',
    path: '/candidates/all',
    icon: <Icon component={RiGroupLine} />,
  },
];

const routeMenus = {client, supplier, resource, marketplace, recruiter};

export default routeMenus;
