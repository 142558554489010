import AppLoader from '@wieldy/components/AppLoader';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {notification} from 'antd';
import RequisitionModal from 'app/modals/RequisitionModal';
import {AppStepsWrapper} from 'app/providers/AppStepProvider';
import {
  useGetRequisition,
  useUpdateRequisition,
} from 'app/services/requisitionService';
import RequisitionSteps, {
  Preview,
} from 'app/shared/requisitions/RequisitionSteps';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

const UpdateRequisition = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {isLoading, data: requisition} = useGetRequisition(id, {
    expand: ['hiring_manager', 'recruiter'],
    include: ['skill_tags'],
  });
  const mutation = useUpdateRequisition(requisition?.id);
  const requisitionModal = new RequisitionModal(requisition);

  const handleSubmit = React.useCallback(
    (postData) => {
      if (!postData) {
        notification.error({description: 'You have not completed all steps'});
      }
      const {
        skill_tags = [],
        hiring_manager,
        recruiter,
        ...formData
      } = postData;
      const skillTags = [];
      for (let skillTag of requisitionModal.skillTags) {
        const findTag = skill_tags.find((item) => item.id === skillTag.id);
        if (!findTag) {
          skillTags.push({
            tag: skillTag.id,
            deleted: true,
          });
        }
      }
      for (let skillTag of skill_tags) {
        if (skillTag?.rstId) continue;
        skillTags.push({
          tag: skillTag.id,
        });
      }
      if (skillTags.length > 0) formData.skill_tags = skillTags;
      formData.hiring_manager = hiring_manager?.id;
      formData.recruiter = recruiter?.id;
      mutation.mutate(formData, {
        onSuccess: () => {
          notification.success({message: 'Requisition updated successfully'});
          navigate(`/requisitions/${requisitionModal.id}/view-requisition`);
        },
      });
    },
    [mutation, navigate, requisitionModal.id, requisitionModal.skillTags],
  );

  if (isLoading) {
    return <AppLoader />;
  }

  if (requisition) {
    requisition.skill_tags = requisitionModal.skillTags;
    requisition.hiring_manager = requisitionModal?.hiringManager;
    requisition.recruiter = requisitionModal?.recruiter;
  }

  return (
    <AppStepsWrapper
      onFinish={handleSubmit}
      isLoading={mutation.isLoading}
      preview={<Preview />}
      dataSource={requisition}
      isUpdated={true}>
      <AppPageContainer
        header={
          <AppPageHeader
            category={'Requisition'}
            title={requisitionModal.jobTitle}
          />
        }>
        <RequisitionSteps />
      </AppPageContainer>
    </AppStepsWrapper>
  );
};

export default UpdateRequisition;
