import './SupplierType.styles.less';

import PropTypes from 'prop-types';

import {supplierTypesNames} from '../../../constants/supplier-types';

const SupplierType = ({type = []}) => {
  return (
    <div className='columnWrapper'>
      {type.map((t) => {
        return <span key={t}>{supplierTypesNames[t]}</span>;
      })}
    </div>
  );
};

SupplierType.propTypes = {
  type: PropTypes.array,
};

export default SupplierType;
