import AppTags from '@wieldy/components/AppTags';
import {capitalizedString} from '@wieldy/utils/helpers/StringHelper';
import {Avatar, Descriptions, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import UserPopover from 'app/shared/components/UserPopover';
import PropTypes from 'prop-types';
import React from 'react';
import {RiBuildingLine} from 'react-icons/ri';

import {SupplierType} from '../../../../../../../shared/supplier';
import {InsuranceSupplierState} from '../../../../../requisitions/ViewRequisition/constatns';

const CompanyDetail = ({company, children}) => {
  const {isSupplier} = useAccountType();

  return (
    <React.Fragment>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-6'
        column={{xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Company Name'>
          {company?.name}
        </Descriptions.Item>
        {isSupplier && (
          <Descriptions.Item label='Supplier type'>
            <SupplierType type={company?.type} />
          </Descriptions.Item>
        )}
        {isSupplier ? (
          <Descriptions.Item label='Service Focus'>
            {company.serviceFocus}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label='Industry'>
            {company?.industry}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Company Size'>
          {company?.companySize}
        </Descriptions.Item>
        <Descriptions.Item label='Engagement Status'>
          {company?.statusName}
        </Descriptions.Item>
        <Descriptions.Item label='Website'>
          {company.website && (
            <Typography.Link
              className={'font-semibold'}
              target='_blank'
              href={company.website}>
              {company.website.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label='LinkedIn Company Page'>
          {company.linkedin && (
            <Typography.Link
              className={'font-semibold'}
              target='_blank'
              href={company.linkedin}>
              {company.linkedin.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label='Joined'>
          {company?.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label='Company ID'>{company?.hid}</Descriptions.Item>
        <Descriptions.Item label='Company Representative'>
          <UserPopover user={company.companyRepresentative} />
        </Descriptions.Item>
        <Descriptions.Item label='Account Manager'>
          <UserPopover user={company.accountManager} />
        </Descriptions.Item>
        {isSupplier && (
          <>
            <Descriptions.Item label='Diversity Status'>
              <AppTags items={company.diversityStatus} />
            </Descriptions.Item>
            <Descriptions.Item label='Industry Focus'>
              <AppTags items={company.industryFocuses} />
            </Descriptions.Item>
            <Descriptions.Item label='Has Insurance'>
              <Descriptions.Item label='Company ID'>
                {company.has_insurance
                  ? InsuranceSupplierState.yes
                  : InsuranceSupplierState.no}
              </Descriptions.Item>
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label='Account Status' span={1}>
          {capitalizedString(company?.account_status)}
        </Descriptions.Item>
        <Descriptions.Item label='Updated' span={1}>
          {company?.updatedAt}
        </Descriptions.Item>
      </Descriptions>

      {company.about && (
        <div className={'mb-8'}>
          <Typography.Title level={4}>About</Typography.Title>
          <Typography.Paragraph>
            <div dangerouslySetInnerHTML={{__html: company.about}} />
          </Typography.Paragraph>
        </div>
      )}

      <div className={'mb-10'}>
        <Typography.Title level={4}>Company Logo</Typography.Title>
        <div className='mb-3'>
          <Avatar
            shape='square'
            src={company.logo}
            size={60}
            icon={<RiBuildingLine fontSize={24} />}
          />
        </div>
      </div>
      {children}
    </React.Fragment>
  );
};

CompanyDetail.propTypes = {
  company: PropTypes.object,
  children: PropTypes.node,
};

export default CompanyDetail;
