import AppGridView from '@wieldy/components/AppGridView';
import AppLoader from '@wieldy/components/AppLoader';
import AppPageSection from '@wieldy/components/pageComponents/AppPageSection';
import {Empty} from 'antd';
import usePageQuery from 'app/hooks/usePageQuery';
import CandidateModal from 'app/modals/CandidateModal';
import {statuses} from 'app/pages/main/candidates/MainContent/constants';
import {useGetAllCandidates} from 'app/services/candidateService';
import ViewAllButton from 'app/shared/buttons/ViewAllButton';
import CandidateCard from 'app/shared/cards/CandidateCard';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';
import React, {memo} from 'react';

const All = () => {
  const {queryInit} = usePageQuery();

  const {data: allData, isLoading} = useGetAllCandidates(
    {
      ...queryInit,
      expand: [...queryInit.expand, 'applicant'],
      include: [...queryInit.include, 'skill_tags', 'completed_engagements'],
      sort: ['-status_changed_at'],
    },
    statuses,
  );

  if (isLoading) {
    return <AppLoader />;
  }

  if (allData?.every(({candidates}) => !candidates.length)) {
    return <Empty description='No Candidates' />;
  }

  return (
    !!allData?.length &&
    allData.map(
      ({candidates, total_count, status}) =>
        !!candidates.length && (
          <AppPageSection
            key={status}
            title={`${capitalizeFirstLetter(status)} Candidates`}
            count={total_count}
            extra={
              total_count > 6 && (
                <ViewAllButton category={status} path='candidates' />
              )
            }>
            <AppGridView
              isAllPages={true}
              key={status}
              data={candidates.slice(0, 6)}
              renderItem={(candidate) => (
                <CandidateCard
                  key={candidate.id}
                  candidate={new CandidateModal(candidate)}
                />
              )}
            />
          </AppPageSection>
        ),
    )
  );
};

export default memo(All);
