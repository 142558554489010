import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, DatePicker, Form} from 'antd';
import RequisitionHelper from 'app/utils/RequisitionHelper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {isValidDateRange} from '../../../../utils/helpers/IsValidDateRange';

const RangePicker = DatePicker.RangePicker;

const InformationForm = ({initialValues, onSave, onContinue}) => {
  const onFormSubmit = (formData) => {
    const {start_end_date, ...rest} = formData;
    onSave({
      ...rest,
      start_date: start_end_date[0]?.unix(),
      end_date: start_end_date[1]?.unix(),
    });
    onContinue();
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={initialValues}>
          <AppFormBody style={{maxWidth: 280}}>
            <Form.Item
              name='job_worksite'
              label='Worksite Type'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Worksite Type'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('worksite type')}
                options={RequisitionHelper.worksites()}
              />
            </Form.Item>

            <Form.Item
              name='job_country'
              label='Worksite Location(s)'
              rules={[
                {
                  required: false,
                  message: getRequiredMessage('Work Location(s)'),
                },
              ]}>
              <AppSelect
                showSearch
                mode='multiple'
                placeholder={getSelectPlaceHolder('worksite location(s)')}
                options={RequisitionHelper.countries()}
              />
            </Form.Item>

            <Form.Item
              name='job_duration'
              label='Duration'
              rules={[
                {
                  required: false,
                  message: getRequiredMessage('Duration'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder('duration')}
                options={RequisitionHelper.durations()}
              />
            </Form.Item>

            <Form.Item
              label={'Start Date - End Date'}
              name={'start_end_date'}
              rules={[
                {
                  required: true,
                  validator: isValidDateRange(
                    getRequiredMessage('Start & End date'),
                  ),
                },
              ]}>
              <RangePicker
                format='MM-DD-YYYY'
                disabledDate={(current) =>
                  current && current < moment().startOf('day')
                }
              />
            </Form.Item>

            <Form.Item
              name='travel_requirements'
              label='Travel Required'
              rules={[
                {
                  required: false,
                  message: getRequiredMessage('Travel Required'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('travel required')}
                options={RequisitionHelper.travelRequirements()}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

InformationForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
};

export default InformationForm;
