import {formatPhoneNumber} from '@wieldy/utils/helpers/StringHelper';
import {DATE_FORMATS} from 'app/config/app';
import {
  PERIODS,
  RESOURCE_WILLING_TO_RELOCATE,
  RESOURCE_WORK_ARRANGEMENT,
  RESOURCE_WORK_LOCATION,
} from 'app/constants';

import {
  BILLING_RATE_PERIODS,
  COUNTRIES,
  EDUCATIONS,
  JOB_STATUSES,
  PRINT_SCANNER_ACCESS,
  RESOURCE_AVAILABILITY,
  RESOURCE_AVAILABILITY_OVERTIME,
  TIMEZONES,
  TRAVEL_AVAILABILITY,
  US_STATES,
  USER_STATUS,
  VISA_STATUSES,
  WORK_COMPUTER_OS_TYPES,
  WORK_COMPUTER_TYPES,
} from '../constants';
import {DURATION} from '../constants/duration';
import {
  formatDate,
  formatSecondsToDate,
} from '../utils/helpers/DataFormatHelper';
import BaseModal from './BaseModal';

const getBillingRate = ({
  billingRate,
  billing_rate,
  billing_rate_min,
  billing_rate_max,
  billing_rate_period,
}) => {
  return `${billingRate} ${
    (billing_rate || (billing_rate_min && billing_rate_max)) &&
    PERIODS[billing_rate_period]
      ? ` / ${PERIODS[billing_rate_period]}`
      : ''
  }`;
};

class ResourceModal extends BaseModal {
  constructor(resource = {}) {
    super(resource);

    this.resource = resource;
    this.id = resource?.id || '';
    this.hid = resource?.hid || '';
    this.created_at = formatDate(resource?.created_at, 'MMM YYYY');
    this.updated_at = formatSecondsToDate(resource?.updated_at);
    this.avatar = resource?.user?.avatar_url ?? '';
    this.title = resource?.title ?? null;
    this.firstName = resource?.first_name ?? '';
    this.lastName = resource?.last_name ?? '';
    this.name = this.getFullName(resource);
    this.type = resource?.type;
    this.initials = this.getInitials(this.name);
    this.email = resource?.email ?? null;
    this.about = resource?.about;
    this.phone_number = resource?.phone_number
      ? formatPhoneNumber(resource?.phone_number)
      : null;

    this.assignment_duration_min =
      DURATION[resource?.assignment_duration_min] ||
      resource?.assignment_duration_min?.replaceAll('_', ' ') ||
      '';
    this.assignment_duration_max =
      DURATION[resource?.assignment_duration_max] ||
      resource?.assignment_duration_max?.replaceAll('_', ' ') ||
      '';
    this.employed_since = resource?.employed_since
      ? formatDate(resource.employed_since, 'MMM YYYY')
      : '';
    this.work_status = resource?.work_status
      ? JOB_STATUSES[resource.work_status]
      : null;
    this.part_time_assignment = !!resource?.part_time_assignment;
    this.available_on = formatDate(resource?.available_on, DATE_FORMATS.md);
    this.available_to = formatDate(resource?.available_to, DATE_FORMATS.md);
    this.overtime_availability =
      RESOURCE_AVAILABILITY_OVERTIME[resource?.overtime_availability] || '';
    this.travel_availability =
      {no: 'No', ...TRAVEL_AVAILABILITY}[resource?.travel_availability] || '';
    this.visa_status = VISA_STATUSES[resource?.visa_status] || null;
    this.timezone = TIMEZONES[resource?.timezone] || resource?.timezone || null;
    this.status = resource?.status ?? null;
    this.statusName = this.status && USER_STATUS[this.status];
    this.country = resource?.country ? COUNTRIES[resource?.country] : null;
    this.us_state = resource?.us_state ? US_STATES[resource?.us_state] : null;
    this.city = resource?.city ?? null;
    this.location = this.getLocation();
    this.country = resource?.country;
    this.availability = resource?.availability ?? null;
    this.willing_to_relocate = resource?.willing_to_relocate
      ? RESOURCE_WILLING_TO_RELOCATE[resource?.willing_to_relocate]
      : null;
    this.work_arrangement = resource?.work_arrangement
      ? RESOURCE_WORK_ARRANGEMENT[resource?.work_arrangement]
      : null;
    this.availabilityName =
      this.availability && RESOURCE_AVAILABILITY[this.availability];
    this.availabilityStatusColor = 'success';
    this.resource_manager = resource?.resource_manager;
    this.resourceManager = resource?.resource_manager
      ? this.getUser(resource?.resource_manager, false)
      : null;
    this.years_experience = resource?.years_experience || 'N/A';
    this.billing_rate_min = resource?.billing_rate_min ?? null;
    this.billing_rate_max = resource?.billing_rate_max ?? null;
    this.billing_rate = resource?.billing_rate;
    this.billingRate = this.formatBillingRateRage(
      this.billing_rate,
      this.billing_rate_min,
      this.billing_rate_max,
      false,
    );
    this.billingRatePeriod = resource?.billing_rate_period;
    this.billing_rate_period =
      resource?.billing_rate_period &&
      resource.billing_rate_period in BILLING_RATE_PERIODS
        ? BILLING_RATE_PERIODS[resource?.billing_rate_period]
        : 'hourly';
    this.billing_rate_negotiations = !!resource?.billing_rate_negotiations;
    this.billing_rate_buy_out = !!resource?.billing_rate_buy_out;
    this.work_conference_room_access = resource?.work_conference_room_access
      ? 'Yes'
      : 'No';
    this.engagements = resource?.engagements ?? 0;
    this.completed_engagements = resource?.completed_engagements ?? 0;
    this.work_location =
      RESOURCE_WORK_LOCATION[resource?.work_location] || 'N/A';
    this.work_computer_type =
      WORK_COMPUTER_TYPES[resource?.work_computer_type] || 'Other';
    this.work_computer_os_type =
      WORK_COMPUTER_OS_TYPES[resource?.work_computer_os_type] || 'Other';
    this.work_printer_scanner =
      PRINT_SCANNER_ACCESS[resource?.work_printer_scanner] || 'No';
    this.work_extra_monitor = resource?.work_extra_monitor ? 'Yes' : 'No';
    this.skills =
      resource?.skill_tags?.data.map(
        ({expertise_level, years_experience, id, tag, last_used}) => ({
          id,
          last_used,
          expertise_level,
          years_experience,
          skill: tag?.name || '',
        }),
      ) || [];
    this.rating_total = resource?.rating_total || 0;
    this.rating_count = resource?.rating_count || 0;
    this.rating = resource?.rating || {
      communication: 0,
      proactiveness: 0,
      productivity: 0,
      quality_and_output: 0,
      reliability_and_consistency: 0,
    };
    this.employment_history = resource?.employment_history || [];
    this.educations = resource?.educations || [];
    this.courses = resource?.courses || [];
    this.certifications = resource?.certifications || [];
    this.languages = resource?.languages || [];
    this.supplier = this.buildExpandSupplier(resource, true);
    this.supplier_active_clients = resource?.supplier_active_clients || 0;
    this.supplier_available_resources =
      resource?.supplier_available_resources || 0;
    this.supplier_completed_engagements =
      resource?.supplier_completed_engagements || 0;
    this.supplier_engagements = resource?.supplier_engagements || 0;
    this.supplier_inquiries = resource?.supplier_inquiries || 0;
    this.supplier_relationship_status =
      resource?.supplier_relationship_status || 'N/A';
    this.supplier_submissions = resource?.supplier_submissions || 0;
    this.education_degree_level =
      EDUCATIONS[resource?.education_degree_level] || null;
    this.bookmarked = resource?.bookmarked;
    this.billingRateLabel = getBillingRate(this);
  }

  getLocation() {
    let locations =
      this.country === 'United States'
        ? [this.city, this.us_state]
        : [this.city, this.country];
    return locations.filter((item) => item !== null).join(', ');
  }
}
export default ResourceModal;
