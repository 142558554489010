import './index.style.less';

import AppScrollbar from '@wieldy/components/AppScrollbar';
import {
  findSelectedKeys,
  getRouteMenus,
} from '@wieldy/utils/helpers/MenuHelper';
import {Layout, Menu} from 'antd';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import {resetPageState} from 'app/store/features/common/commonSlice';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {routeMenus} from '../../../routes';
import SupplierHelper from '../../../utils/SupplierHelper';
import SidebarHeader from './SidebarHeader';

const AppSidebar = ({isCollapsed, toggleCollapsed, isMobileView = false}) => {
  const {pathname} = useLocation();
  const {
    isResource,
    isHiring,
    isManager,
    isAgencyRecruiter,
    isClientRecruiter,
  } = useRole();
  const {isSupplier, isClient, isMarketplace} = useAccountType();
  const dispatch = useDispatch();
  const pageStates = useSelector(({common}) => common);
  const supplierType = useSelector(
    ({user}) => user.currentAccount.supplier?.type,
  );

  const handleMenuItemClick = useCallback(() => {
    const isState = Object.values(pageStates).some((el) => el);
    if (isState) {
      dispatch(resetPageState());
    }
  }, [dispatch, pageStates]);

  const menuItems = React.useMemo(() => {
    let routeMenuItems;
    if (isResource) {
      routeMenuItems = routeMenus[USER_CREDENTIALS.USER_ROLES.resource];
    } else if (isClientRecruiter) {
      routeMenuItems = routeMenus[USER_CREDENTIALS.USER_ROLES.recruiter];
    } else if (isMarketplace && !isClient && !isSupplier) {
      routeMenuItems = routeMenus[USER_CREDENTIALS.USER_TYPES.marketplace];
    } else if (isSupplier) {
      routeMenuItems =
        isManager || isAgencyRecruiter
          ? routeMenus[USER_CREDENTIALS.USER_TYPES.supplier].filter(
              ({id}) => id !== 'administration' && id !== 'divider-2',
            )
          : routeMenus[USER_CREDENTIALS.USER_TYPES.supplier].filter(
              ({access}) => {
                if (access && access.supplierType && supplierType) {
                  return SupplierHelper.hasAccessByType(
                    supplierType,
                    access.supplierType,
                  );
                }

                return true;
              },
            );
    } else if (isClient) {
      routeMenuItems = isHiring
        ? routeMenus[USER_CREDENTIALS.USER_TYPES.client].filter(
            ({id}) => id !== 'administration' && id !== 'divider-2',
          )
        : routeMenus[USER_CREDENTIALS.USER_TYPES.client];
    }

    return getRouteMenus(routeMenuItems, handleMenuItemClick);
  }, [
    isResource,
    isMarketplace,
    isClient,
    isSupplier,
    handleMenuItemClick,
    isManager,
    isAgencyRecruiter,
    supplierType,
    isHiring,
    isClientRecruiter,
  ]);

  const segments =
    pathname === '/'
      ? ['dashboard']
      : pathname.replace(/^\/|\/$/g, '').split('/');
  let defaultOpenKeys = `submenu-${segments[0]}`;
  let defaultSelectedKeys = findSelectedKeys(defaultOpenKeys, segments);
  return (
    <Layout.Sider
      className='app-sidebar'
      width={238}
      collapsedWidth={70}
      trigger={null}
      collapsible
      collapsed={isCollapsed}>
      <SidebarHeader
        isCollapsed={isCollapsed}
        toggleCollapsed={toggleCollapsed}
        isMobileView={isMobileView}
      />

      <AppScrollbar>
        <Menu
          style={{fontSize: 14}}
          onClick={isMobileView ? toggleCollapsed : undefined}
          className='app-sidebar-menu'
          theme='dark'
          selectedKeys={defaultSelectedKeys}
          defaultOpenKeys={[defaultOpenKeys]}>
          {menuItems}
        </Menu>
      </AppScrollbar>
    </Layout.Sider>
  );
};

AppSidebar.propTypes = {
  isCollapsed: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
  isMobileView: PropTypes.bool,
};

export default React.memo(AppSidebar);
